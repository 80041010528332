@import url(https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@300;400;700&display=swap);
body{font-family:"Noto Sans JP",sans-serif !important}.App{font-family:"Noto Sans JP",sans-serif !important}.App .wrapperSection{display:-webkit-flex;display:flex;-webkit-justify-content:center;justify-content:center}.App .wrapperSection .wrapperButtons{display:-webkit-flex;display:flex;-webkit-justify-content:space-around;justify-content:space-around;margin-bottom:1rem}.App .wrapperSection .wrapperButtons .button{width:150px;padding:.5rem;box-sizing:border-box;background-color:#c64756;outline:none;border:none;border-radius:2rem}.App .wrapperSection .wrapperButtons .button:not(:last-child){margin-right:1rem}.App .wrapperSection .wrapperButtons .button:hover{cursor:pointer}
.wrapperError{text-align:center;font-size:1rem;color:#fad586;border:solid 1px #c64756;background-color:#5f0d0d;box-sizing:border-box;width:340px;margin:1rem auto;border-radius:1rem}
.subtitle{font-size:2.5rem;color:#fad586}
.form{padding-top:10rem;text-align:center}.form .wrapperInputs{display:-webkit-flex;display:flex;-webkit-justify-content:center;justify-content:center;-webkit-align-items:center;align-items:center}.form .wrapperInputs .input{padding:1rem;color:#184d47;width:600px;font-size:1rem;border:solid 2px #c64756;border-radius:2rem;margin:0 1rem 0 0;box-sizing:border-box;outline:none}.form .wrapperInputs .input::-webkit-input-placeholder{color:#184d47;font-size:.8rem;outline:none}.form .wrapperInputs .input:-ms-input-placeholder{color:#184d47;font-size:.8rem;outline:none}.form .wrapperInputs .input::placeholder{color:#184d47;font-size:.8rem;outline:none}.form .wrapperInputs .button{background-color:#c64756;padding:1rem 2rem;font-size:1.2rem;border-radius:2rem;border:none;color:#fad586;box-sizing:border-box;width:320px;outline:none}.form .wrapperInputs .button:hover{cursor:pointer;background-color:#6d1f28;width:330px}@media only screen and (max-width: 750px){.form{padding-top:18rem}.form .wrapperInputs{-webkit-flex-direction:column;flex-direction:column}.form .wrapperInputs .input{width:320px;margin:0 0 1rem 0}}
.title{font-size:3.5rem;font-weight:700;color:#fad586;text-align:center}
.wrapperHeader{position:fixed;display:-webkit-flex;display:flex;-webkit-justify-content:center;justify-content:center;-webkit-align-items:center;align-items:center;padding:1rem 0;width:100%;background:#184d47;background:linear-gradient(112deg, #184d47 51%, #96bb7c 62%)}
.Card{width:340px;padding:1rem .5rem;border:1px solid #96bb7c 62%;box-sizing:border-box;margin-bottom:1rem;border-radius:2rem;box-shadow:5px 5px 10px #184d47}.Card .wrapperImg{display:-webkit-flex;display:flex;-webkit-justify-content:center;justify-content:center;-webkit-align-items:center;align-items:center;width:100%;padding:1rem 3rem;box-sizing:border-box}.Card .wrapperImg .img{width:100%;border-radius:1rem}.Card .wrapperData .wrapperGeneralInfo{text-align:center;margin-bottom:1rem}.Card .wrapperData .wrapperInfo{display:-webkit-flex;display:flex;-webkit-justify-content:space-around;justify-content:space-around}.Card .wrapperData .wrapperInfo .wrappericons{display:-webkit-flex;display:flex;-webkit-justify-content:center;justify-content:center;-webkit-align-items:center;align-items:center}.Card .wrapperData .wrapperInfo .wrappericons .icon{width:1.5rem;margin-right:.3rem}.Card .wrapperData .wrapperInfo .wrappericons .data{font-size:1.5rem;color:#c64756;font-weight:700}.Card .wrapperData .wrapperButton{padding:1rem 2rem;display:-webkit-flex;display:flex;box-sizing:border-box;-webkit-justify-content:center;justify-content:center;-webkit-align-items:center;align-items:center}.Card .wrapperData .wrapperButton .buttonImg{padding:.6rem 1rem;width:100%;text-decoration:none;color:#000;background-color:#c64756;border-radius:2rem;text-align:center;outline:none;transition:background-color .3s ease}.Card .wrapperData .wrapperButton .buttonImg:hover{background-color:#741b26;color:#fff}.Card .wrapperData .wrapperButton .buttonImg:visited{background-color:#833941;color:#fff}.Card .text{font-size:1rem;font-weight:300}.Card .text::first-letter{color:#c64756;text-transform:uppercase;font-weight:700}
.wrapperList{display:-webkit-flex;display:flex;-webkit-justify-content:space-around;justify-content:space-around;-webkit-flex-wrap:wrap;flex-wrap:wrap;padding:1rem 2rem;box-sizing:border-box}
.sk-folding-cube{margin:20px auto;width:40px;height:40px;position:relative;-webkit-transform:rotateZ(45deg);transform:rotateZ(45deg)}.sk-folding-cube .sk-cube{float:left;width:50%;height:50%;position:relative;-webkit-transform:scale(1.1);transform:scale(1.1)}.sk-folding-cube .sk-cube:before{content:"";position:absolute;top:0;left:0;width:100%;height:100%;background:#c64756;-webkit-animation:sk-foldCubeAngle 2.4s infinite linear both;animation:sk-foldCubeAngle 2.4s infinite linear both;-webkit-transform-origin:100% 100%;transform-origin:100% 100%}.sk-folding-cube .sk-cube2{-webkit-transform:scale(1.1) rotateZ(90deg);transform:scale(1.1) rotateZ(90deg)}.sk-folding-cube .sk-cube3{-webkit-transform:scale(1.1) rotateZ(180deg);transform:scale(1.1) rotateZ(180deg)}.sk-folding-cube .sk-cube4{-webkit-transform:scale(1.1) rotateZ(270deg);transform:scale(1.1) rotateZ(270deg)}.sk-folding-cube .sk-cube2:before{-webkit-animation-delay:.3s;animation-delay:.3s}.sk-folding-cube .sk-cube3:before{-webkit-animation-delay:.6s;animation-delay:.6s}.sk-folding-cube .sk-cube4:before{-webkit-animation-delay:.9s;animation-delay:.9s}@-webkit-keyframes sk-foldCubeAngle{0%,10%{-webkit-transform:perspective(140px) rotateX(-180deg);transform:perspective(140px) rotateX(-180deg);opacity:0}25%,75%{-webkit-transform:perspective(140px) rotateX(0deg);transform:perspective(140px) rotateX(0deg);opacity:1}90%,100%{-webkit-transform:perspective(140px) rotateY(180deg);transform:perspective(140px) rotateY(180deg);opacity:0}}@keyframes sk-foldCubeAngle{0%,10%{-webkit-transform:perspective(140px) rotateX(-180deg);transform:perspective(140px) rotateX(-180deg);opacity:0}25%,75%{-webkit-transform:perspective(140px) rotateX(0deg);transform:perspective(140px) rotateX(0deg);opacity:1}90%,100%{-webkit-transform:perspective(140px) rotateY(180deg);transform:perspective(140px) rotateY(180deg);opacity:0}}
