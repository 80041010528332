.wrapperError {
  text-align: center;
  font-size: 1rem;
  color: #fad586;
  border: solid 1px #c64756;
  background-color: rgb(95, 13, 13);
  box-sizing: border-box;
  width: 340px;
  margin: 1rem auto;
  border-radius: 1rem;
}
